import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BaseSection = styled.div`
  background-color: var(--background-color);
  padding: 0rem 4rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  width: 100%;
`;

export const ContentImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 5rem;
`;

export const TextContent = styled.div`
  flex: 1;
  max-width: 60%;
  min-width: 300px;
  overflow-y: auto;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 60%;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    margin-left: 10px;
  }
`;

export const EditableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  textarea {
    resize: vertical;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }

  input[type='file'] {
    margin-top: 8px;
  }
`;

export const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SectionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5rem;
`;

export const SectionSeparator = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

export const IconTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: midnightblue;
`;

export const TextCustom = styled.div`
  font-size: 1rem;
  padding: 0.5rem 0 1rem;
  line-height: 1.5;
  color: black;
  
  &.ql-editor {
    // Ensure Quill editor styles apply correctly
    .ql-align-center {
      text-align: center !important;
    }
    
    .ql-align-right {
      text-align: right !important;
    }
    
    .ql-align-justify {
      text-align: justify !important;
    }
    
    .ql-align-left {
      text-align: left !important;
    }
  }
`;