import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import {
  sidebarNavigation,
  SidebarNavigationItem,
} from '../../sidebar-navigation'
import { t } from 'i18next'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import {
  CloseButton,
  ContactInfo,
  ContactItemStyle,
  ContactRightText,
  HeaderWrapper,
  HorizontalLine,
  LogoImageStyle,
  MenuContent,
  MenuItems,
  Overlay,
  SliderMenuContainer,
} from './styles'
import R from 'app/assets/R'
import { useAppDispatch } from 'app/redux/hooks'
import { setCurrentPath } from 'app/redux/slices/pathSlice'

interface SliderContentProps {
  setCollapsed: (isCollapsed: boolean) => void
  isCollapsed?: boolean
}

const MobileSliderMenu: React.FC<SliderContentProps> = ({
  setCollapsed,
  isCollapsed = true,
}) => {
  const dispatch = useAppDispatch()
  const { verifyPermission } = useVerifyPermission()

  const onClickMenuItem = (url?: string) => {
    setCollapsed(true)
    dispatch(setCurrentPath(url))
  }

  const listSidebarNavigationWithPermission = useMemo(() => {
    return sidebarNavigation.filter(nav => {
      if (!nav.permission) {
        return true
      }
      return verifyPermission(nav.permission)
    })
  }, [verifyPermission])

  return (
    <SliderMenuContainer isCollapsed={isCollapsed}>
      <Overlay isCollapsed={isCollapsed} onClick={() => setCollapsed(true)} />
      <MenuContent isCollapsed={isCollapsed}>
        <HeaderWrapper>
          <LogoImageStyle
            src="https://storage.googleapis.com/logistic_bucket_dev/None/logo_le_vy.jpg"
            alt="Logo"
          />
          <ContactRightText>{t(R.strings.companyName)}</ContactRightText>
          <CloseButton onClick={() => setCollapsed(true)}>×</CloseButton>
        </HeaderWrapper>
        <HorizontalLine />
        <MenuItems>
          {listSidebarNavigationWithPermission.map(
            (item: SidebarNavigationItem) => (
              <li key={item.key} onClick={() => onClickMenuItem(item.url)}>
                <a href={`#${item.key}`}>{t(item.title)}</a>
              </li>
            ),
          )}
        </MenuItems>
        <HorizontalLine />
        <ContactInfo>
          <ContactItemStyle>
            <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: '8px' }} />
            (+84) 91 891 3838
          </ContactItemStyle>
          <ContactItemStyle>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ marginRight: '8px' }}
            />
            {t(R.strings.address)}
          </ContactItemStyle>
        </ContactInfo>
      </MenuContent>
    </SliderMenuContainer>
  )
}

export default MobileSliderMenu
