import styled from 'styled-components'

export const HeaderStyle = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  position: sticky;
  top: 0;
`

export const TopBarStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  z-index: 999;
  position: relative;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    padding: 0.5rem;
  }
`

export const LogoStyle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-bottom: 0;
  }
`

export const LogoImageStyle = styled.img`
  height: 40px;

  @media only screen and (max-width: 768px) {
    height: 3.5rem;
    margin-right: 8px;
  }
`

export const CompanyNameStyle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: midnightblue;
  flex: 2;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`

export const ContactInfoStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const ContactItemWrapper = styled.div`
  margin-right: 2rem;

  @media only screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`

export const ContactItemStyle = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`

export const LoginButton = styled.button`
  background-color: midnightblue;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
`

export const MainHeader = styled.div<{ isTwoColumnsLayout: boolean }>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  ${({ isTwoColumnsLayout }) =>
    isTwoColumnsLayout
      ? `
    width: calc(100% - 200px);
    transition: width 0.2s;
  `
      : `
    width: 100%;
  `}

  @media only screen and (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    height: 60px;

    ${({ isTwoColumnsLayout }) =>
      isTwoColumnsLayout
        ? `
      width: calc(100% - 200px);
      transition: width 0.2s;
    `
        : `
      width: 100%;
    `}
  }
`

export const MenuIconStyle = styled.div`
  font-size: 24px;
  cursor: pointer;
  color: midnightblue;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  @media only screen and (max-width: 768px) {
    font-size: 30px;
    color: midnightblue;
  }
`
