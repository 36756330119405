import styled from 'styled-components';

export const ScrollWrapper = styled.div`
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BaseSection = styled.div`
  background-color: var(--background-color);
  color: var(--primary-color);
  padding: 1rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  width: 100%;
`;

export const EditButtonWrapper = styled.div`
  flex-shrink: 0;
`;

export const ContentImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextContent = styled.div`
  width: 25rem;
`;

export const ImageWrapper = styled.div`
  width: 25rem;

  img {
    width: 25rem;
    height: 20rem;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    margin-left: 10px;
  }
`;

export const ImagePreview = styled.div`
  img {
    width: 25rem;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 8px;
  }
`;

export const EditableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  textarea {
    resize: vertical;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }

  input[type='text'] {
    margin-top: 8px;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
`;

export const SectionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: white;
  padding: 0 4rem 1rem;
  gap: 2rem;
`;

export const SectionSeparator = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: midnightblue;
  text-align: center;
`;

export const TextCustom = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  color: black;
  
  &.ql-align-left {
    text-align: left !important;
  }

  &.ql-align-center {
    text-align: center !important;
  }

  &.ql-align-right {
    text-align: right !important;
  }

  &.ql-align-justify {
    text-align: justify !important;
  }
`;


export const IconTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;

  svg,
  img {
    margin-right: 8px;
  }
`;

export const FirstSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 0 0;
  background-color: white;
`;

export const FirstSectionTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  max-width: 86rem;
  margin: 0 auto;
  color: midnightblue;
`;

export const FirstSectionDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1rem;
  max-width: 86rem;
  margin: 0 auto;

  &.ql-editor {
    .ql-align-center {
      text-align: center !important;
    }
    
    .ql-align-left {
      text-align: left !important;
    }

    .ql-align-right {
      text-align: right !important;
    }

    .ql-align-justify {
      text-align: justify !important;
    }
  }
`;
