/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, RefObject } from 'react'
import { LayoutMain, LayoutMaster } from '../styles'
import { MainContent } from '../../MainContent'
import { MainHeader } from '../../MainHeader'
import { MobileDashboardPage } from 'app/page/menu/dashboard/mobile-dashboard'
import { MobileFooterPage } from 'app/page/menu/footer/mobile-footer'
import { MobileTeamPage } from 'app/page/menu/team/mobile-team'
import { MobileServicesPage } from 'app/page/menu/services/mobile-services'
import { MobileProjectsPage } from 'app/page/menu/projects/mobile-projects'
import { MobilePartnerPage } from 'app/page/menu/partner/mobile-partner'
import { MobileIntroducePage } from 'app/page/menu/introduce/mobile-introduce'
import { useAppSelector } from 'app/redux/hooks'
import {
  DASHBOARD_PATH,
  INTRODUCE_PATH,
  PARTNER_PATH,
  PROJECTS_PATH,
  SERVICES_PATH,
  TEAM_PATH,
} from 'app/components/router/route-path'

interface MobileMainLayoutProps {
  isTwoColumnsLayout: boolean
  siderCollapsed: boolean
  toggleSider: () => void
}

export const MobileMainLayout: React.FC<MobileMainLayoutProps> = ({
  isTwoColumnsLayout,
}) => {
  const refDashboard = useRef<HTMLDivElement>(null)
  const refIntroduce = useRef<HTMLDivElement>(null)
  const refServices = useRef<HTMLDivElement>(null)
  const refTeam = useRef<HTMLDivElement>(null)
  const refProjects = useRef<HTMLDivElement>(null)
  const refPartner = useRef<HTMLDivElement>(null)
  const currentKey = useAppSelector(state => state?.path)?.path
  const containerRef = useRef<any>(null)
  const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
    if (ref?.current) {
      const offset = 110 // Độ lệch 50px

      const top = ref?.current?.offsetTop
      const elementPosition = top + window.pageYOffset
      const offsetPosition = elementPosition - offset
      containerRef?.current?.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      switch (currentKey) {
        case DASHBOARD_PATH:
          scrollToSection(refDashboard)
          break
        case INTRODUCE_PATH:
          scrollToSection(refIntroduce)
          break
        case SERVICES_PATH:
          scrollToSection(refServices)
          break
        case TEAM_PATH:
          scrollToSection(refTeam)
          break
        case PROJECTS_PATH:
          scrollToSection(refProjects)
          break
        case PARTNER_PATH:
          scrollToSection(refPartner)
          break
        default:
          break
      }
    }, 300)

    return () => clearTimeout(timer)
  }, [currentKey, containerRef])

  return (
    <LayoutMaster>
      <MainHeader isTwoColumnsLayout={isTwoColumnsLayout} />
      <LayoutMain>
        <MainContent
          ref={containerRef}
          id="main-content"
          $isTwoColumnsLayout={isTwoColumnsLayout}
        >
          <div ref={refDashboard}>
            <MobileDashboardPage />
          </div>
          <div ref={refIntroduce}>
            <MobileIntroducePage />
          </div>
          <div ref={refServices}>
            <MobileServicesPage />
          </div>
          <div ref={refTeam}>
            <MobileTeamPage />
          </div>
          <div ref={refProjects}>
            <MobileProjectsPage />
          </div>
          <div ref={refPartner}>
            <MobilePartnerPage />
          </div>
          <MobileFooterPage />
        </MainContent>
      </LayoutMain>
    </LayoutMaster>
  )
}
