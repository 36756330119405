import { FONT_WEIGHT } from 'parkway-web-common';
import styled from 'styled-components';

export const ScrollWrapper = styled.div`
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BaseSection = styled.div`
  background-color: var(--background-color);
  color: var(--primary-color);
  padding: 2rem 0 2rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
`;

export const TextContent = styled.div`
  flex: 1;
  max-width: 30rem;
`;

export const ImageWrapper = styled.div`
  flex: 1;
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SmallImagesWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SmallImage = styled.div`
  flex: 1;

  img {
    width: 50rem;
    height: 30rem;
    object-fit: cover;
  }
`;

export const SectionSeparator = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

export const Title = styled.div`
  font-size: 2.5rem;
  color: midnightblue;
  font-weight: ${FONT_WEIGHT.bold};
  padding: 0 0 2rem;
`;

export const TextCustom = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  padding-bottom: 1.5rem;
  color: black;

  .no-padding {
    padding: 0;
    margin: 0;
  }

  .ql-align-center {
    text-align: center !important;
  }

  .ql-align-left {
    text-align: left !important;
  }

  .ql-align-right {
    text-align: right !important;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const SectionsContainer = styled.div`
  background-color: white;
  padding: 0 4rem 1rem;
`;
