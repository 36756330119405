import { FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  @media (max-width: 768px) {
    .ant-card-body {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
    }
  }
`

export const BaseSection = styled.div`
  @media (max-width: 768px) {
    background-color: var(--background-color);
    padding: 1rem 0;
  }
`

export const ContentWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`

export const TextContent = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding: 0 1rem;
  }
`

export const SectionSeparator = styled.hr`
  @media (max-width: 768px) {
    border: none;
    border-top: 1px solid #ccc;
    width: 80%;
    margin: 0 1rem;
  }
`

export const Title = styled.div`
  @media (max-width: 768px) {
    font-size: 2.5rem;
    color: midnightblue;
    font-weight: ${FONT_WEIGHT.bold};
    padding: 0 0 0.5rem;
    text-align: center;
  }
`

export const TextCustom = styled.div`
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    padding: 0 1rem 0;
    color: black;
  }
`

export const SectionsContainer = styled.div`
  @media (max-width: 768px) {
    background-color: white;
    padding: 1rem;
  }
`

export const ImageWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
`

export const SmallImagesWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const SmallImage = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    max-width: 20rem;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
`
